<template>
  <v-row class="mt-0" v-if="missions.length > 0">
    <v-col
      v-for="mission of missions"
      :key="mission.id"
      md="4"
      sm="6"
      cols="12"
    >
      <archived-mission :mission="mission"></archived-mission>
    </v-col>
  </v-row>
  <div v-else class="text-center py-4">
    <h1 class="heading app-dark-gray--text font-weight-light">
      {{ $t('missions.noMissionsFound') }}
    </h1>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ArchivedMission from './ArchivedMission.vue';

export default {
  name: 'archived-missions',
  components: {
    ArchivedMission
  },
  computed: {
    ...mapGetters(['missions'])
  }
};
</script>
