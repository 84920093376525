<template>
  <div>
    <v-card v-bind:class="[topBorderClass]" class="mt-2" tile>
      <v-toolbar color="white" flat>
        <v-toolbar-title
          v-bind:class="[titleTextColorClass]"
          class="font-weight-light"
        >
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="closeTasksPanel"
          text
          icon
          @click="closeTasksPanel"
          color="app-red"
          class="mr-n-0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-if="tasks.length === 0" class="text-center pb-2">
        <span class="text-subtitle-1 font-weight-light">
          {{ $t('tasks.noTasksFound') }}
        </span>
      </div>
    </v-card>
    <div v-for="task in tasks" :key="task.id" class="my-2">
      <slot v-bind:task="task"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tasks-list',
  props: {
    tasks: {
      type: Array,
      required: true
    },
    topBorderClass: {
      type: String,
      default: 'border-top-blue'
    },
    titleTextColorClass: {
      type: String,
      default: 'app-blue--text'
    },
    title: {
      type: String,
      required: true
    },
    closeTasksPanel: {
      type: Function
    }
  }
};
</script>
