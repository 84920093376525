<template>
  <v-card class="pa-2 pb-0" tile :ripple="false">
    <v-container class="pa-0">
      <v-card-title class="py-0 mb-2 no-flex-wrap">
        <v-spacer></v-spacer>
        <actions-menu
          :canReopen="true"
          @reopen="reopenTask"
          @delete="deleteTask"
        ></actions-menu>
      </v-card-title>
      <v-row align="center" justify="center" class="py-2">
        <v-col
          class="pt-0"
          v-bind:class="{
            'col-12': !task.requireApprove,
            'col-11': task.requireApprove
          }"
        >
          <v-card-title class="text-subtitle-1 py-0">
            <span class="two-lines font-weight-regular">
              {{ task.title }}
            </span>
          </v-card-title>
          <v-card-text class="text-body-2 py-0" v-if="task.notes">
            <span class="font-weight-light one-line">
              {{ task.notes }}
            </span>
          </v-card-text>
        </v-col>
        <v-col v-if="task.requireApprove" cols="1" class="pt-0 px-0">
          <v-icon small color="app-orange"> mdi-circle </v-icon>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="pt-0 pb-1">
      <task-tags v-if="task.tags.length > 0" :tags="task.tags"></task-tags>
      <div v-else>&nbsp;</div>
      <v-spacer></v-spacer>
      <v-row align="center" justify="end" class="pr-1">
        <ruby-label :ruby="task.ruby" :text-color="rubyColorText"></ruby-label>
      </v-row>
    </v-card-actions>
    <v-divider class="mt-2" light></v-divider>
    <v-card-actions>
      <div @click.stop="openAssignedUsers()">
        <assigned-users
          class="cursor"
          :assignedUsers="assignedUsers"
        ></assigned-users>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { DELETE_GROUP_TASK } from '../../../../store/tasks/types';
import RubyLabel from '../../../shared/RubyLabel';
import TaskTags from '../../../tasks/TaskTags';
import ManageGroupTaskModal from '../../tasks/ManageGroupTaskModal.vue';
import AssignedUsersModal from '../../AssignedUsersModal';
import ActionsMenu from '../../../shared/ActionsMenu.vue';
import AssignedUsers from '../../AssignedUsers';
import ConfirmModal from '../../../shared/ConfirmModal';
import tasksRubyColorMixin from '../../../../mixins/task.ruby.color.mixin';
import manageModalsType from '../../../../enums/manage.modals.types';

export default {
  components: {
    RubyLabel,
    TaskTags,
    ActionsMenu,
    AssignedUsers
  },
  props: {
    task: Object
  },
  mixins: [tasksRubyColorMixin],
  computed: {
    ...mapGetters(['groupMembers']),
    assignedUsers() {
      return this.task.assignedUsers?.length > 0 ? this.task.assignedUsers : [];
    }
  },
  methods: {
    reopenTask() {
      this.$root.$modal.show(ManageGroupTaskModal, {
        selectedTask: { ...this.task },
        members: this.groupMembers,
        shouldUpateFilter: false,
        modalType: manageModalsType.reopen,
        title: this.$i18n.t(`tasks.reopen.${this.task.type}`)
      });
    },
    openAssignedUsers() {
      this.$root.$modal.show(
        AssignedUsersModal,
        {
          itemName: this.task.title,
          assignedUsers: this.assignedUsers
        },
        {
          width: '360px'
        }
      );
    },
    async deleteTask() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('tasks.deleteTaskConfirm')
      });

      if (!confirmed) return;

      const { id } = this.$route.params;

      this.$store.dispatch(DELETE_GROUP_TASK, {
        groupId: id,
        task: this.task
      });
    }
  }
};
</script>
