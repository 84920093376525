<template>
  <v-row class="mt-0">
    <v-col
      v-for="tasksList of tasksLists"
      :key="tasksList.name"
      md="4"
      sm="12"
      cols="12"
    >
      <tasks-list
        :title="$t(tasksList.i18nKey)"
        :tasks="tasksList.tasks"
        :topBorderClass="tasksList.borderClass"
        :titleTextColorClass="tasksList.textClass"
      >
        <template #default="{ task }">
          <archived-task :task="task" :isLeader="true"></archived-task>
        </template>
      </tasks-list>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import TasksList from '../../../tasks/TasksList';
import ArchivedTask from './ArchivedTask.vue';
import tasksListsService from '../../../../services/tasks.lists.service';

export default {
  name: 'archived-tasks',
  components: {
    TasksList,
    ArchivedTask
  },
  computed: {
    ...mapGetters(['todos', 'dailies', 'weeklies']),
    tasksLists() {
      return tasksListsService.getTasksListsConfiguration(
        this.todos,
        this.dailies,
        this.weeklies
      );
    }
  }
};
</script>
