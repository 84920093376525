<template>
  <v-card class="px-2 pb-0" tile :ripple="false">
    <v-card-title class="pt-2 pb-0 mb-0 no-flex-wrap">
      <v-spacer></v-spacer>
      <actions-menu
        :canReopen="reward.archived"
        @reopen="reopenReward"
        @delete="deleteReward"
      ></actions-menu>
    </v-card-title>
    <v-row align="center" justify="center">
      <v-col sm="9" cols="8" class="pl-2 pt-0">
        <v-card-text class="text-subtitle-1">
          <span class="one-line">{{ reward.name }}</span>
        </v-card-text>
      </v-col>
      <v-col sm="3" cols="4" class="pl-0">
        <v-row align="center" justify="center">
          <ruby-label :ruby="reward.ruby"></ruby-label>
        </v-row>
      </v-col>
    </v-row>
    <v-card-actions class="pt-0 pb-2">
      <reward-type-label :reward="reward" class="mr-2"></reward-type-label>
      <v-icon v-if="reward.requireApprove" small color="app-orange">
        mdi-circle
      </v-icon>
    </v-card-actions>
    <v-divider class="mt-1" light></v-divider>
    <v-card-actions>
      <div @click.stop="openAssignedUsers()">
        <assigned-users
          class="cursor"
          :assignedUsers="assignedUsers"
        ></assigned-users>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionsMenu from '../../../shared/ActionsMenu.vue';
import ManageGroupRewardModal from '../../rewards/ManageGroupRewardModal';
import RewardTypeLabel from '../../../rewards/RewardTypeLabel';
import RubyLabel from '../../../shared/RubyLabel';
import AssignedUsers from '../../AssignedUsers';
import AssignedUsersModal from '../../AssignedUsersModal';
import ConfirmModal from '../../../shared/ConfirmModal';
import { DELETE_GROUP_REWARD } from '../../../../store/rewards/types';
import rewardTypes from '../../../../enums/reward.types';
import manageModalsType from '../../../../enums/manage.modals.types';

export default {
  components: {
    ActionsMenu,
    RewardTypeLabel,
    RubyLabel,
    AssignedUsers
  },
  props: {
    reward: { type: Object, require: true }
  },
  computed: {
    ...mapGetters(['groupMembers']),
    assignedUsers() {
      return this.reward.assignedUsers?.length > 0
        ? this.reward.assignedUsers
        : [];
    }
  },
  data() {
    return {
      rewardTypes
    };
  },
  methods: {
    reopenReward() {
      this.$root.$modal.show(ManageGroupRewardModal, {
        selectedReward: { ...this.reward },
        members: this.groupMembers,
        title: this.$i18n.t('rewards.reopenReward'),
        modalType: manageModalsType.reopen
      });
    },
    openAssignedUsers() {
      this.$root.$modal.show(
        AssignedUsersModal,
        {
          itemName: this.reward.name,
          assignedUsers: this.assignedUsers
        },
        {
          width: '360px'
        }
      );
    },
    async deleteReward() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$root.$t('rewards.deleteRewardConfirm')
      });

      const { id } = this.$route.params;
      const data = {
        groupId: id,
        rewardId: this.reward.id
      };

      if (confirmed) {
        this.$store.dispatch(DELETE_GROUP_REWARD, data);
      }
    }
  }
};
</script>
