<template>
  <div>
    <v-chip
      v-for="tag of tags"
      :key="tag.id"
      small
      color="app-blue"
      dark
      class="font-weight-regular ml-0 hidden-sm-and-down tag mr-1"
    >
      <span class="text-caption">
        {{ $t(`tags.${tag.name}`) }}
      </span>
    </v-chip>
    <div class="mt-0 hidden-md-and-up">
      <v-chip
        v-if="tags.length > 0"
        dark
        small
        color="app-blue"
        class="tag mr-1"
      >
        {{ $t(`tags.${tags[0].name}`) }}
      </v-chip>
      <v-menu offset-y v-if="tags.length > 1">
        <template v-slot:activator="{ on }">
          <v-chip v-on="on" dark color="app-blue" small class="px-1 tag">
            +{{ tags.length - 1 }}
          </v-chip>
        </template>
        <v-list class="py-0">
          <span v-for="(tag, index) of tags" :key="tag.id">
            <v-list-item v-if="index !== 0">
              <v-chip dark small color="app-blue" class="ma-0 tag">
                {{ $t(`tags.${tag.name}`) }}
              </v-chip>
            </v-list-item>
          </span>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'task-tags',
  props: {
    tags: {
      type: Array,
      required: true
    }
  }
};
</script>
