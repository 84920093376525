<template>
  <fragment>
    <v-progress-linear
      class="mr-3"
      :value="percentages"
      height="8"
    ></v-progress-linear>
    <v-chip color="app-blue" small dark class="font-weight-medium py-0">
      <span>{{ percentages }}%</span>
    </v-chip>
  </fragment>
</template>

<script>
export default {
  name: 'mission-progress-bar',
  props: {
    mission: Object
  },
  computed: {
    percentages() {
      let result = 0;

      if (this.mission.id && this.mission.tasks?.length > 0) {
        const completedTasks = this.mission.tasks.filter(t => t.completed);
        result = Math.floor(
          (completedTasks.length / this.mission.tasks.length) * 100
        );
      }

      return result;
    }
  }
};
</script>
