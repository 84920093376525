<template>
  <div>
    <v-container fluid v-if="currentGroup" class="pa-0 mb-10">
      <v-row align="center" justify="center">
        <v-col md="6" sm="12" cols="12" class="mt-5 pb-0">
          <group-archive-filter
            @changeArchivedItems="changeArchivedItems"
          ></group-archive-filter>
        </v-col>
      </v-row>
      <component v-if="!loading" :is="component"></component>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GroupArchiveFilter from '../../components/groups/archive/GroupArchiveFilter.vue';
import { GET_GROUP_TASKS } from '../../store/tasks/types';
import { GET_GROUP_REWARDS } from '../../store/rewards/types';
import { GET_GROUP_MISSIONS } from '../../store/missions/types';
import { GET_MEMBERS } from '../../store/group-members/types';
import ArchivedTasks from '../../components/groups/archive/tasks/ArchivedTasks.vue';
import ArchivedRewards from '../../components/groups/archive/rewards/ArchivedRewards.vue';
import ArchivedMissions from '../../components/groups/archive/missions/ArchivedMissions.vue';
import dashboardItems from '../../enums/dashboard.items';
import missionStatuses from '../../enums/mission.statuses';

export default {
  components: {
    GroupArchiveFilter
  },
  computed: {
    ...mapGetters(['currentGroup'])
  },
  data() {
    return {
      loading: false,
      component: ArchivedTasks
    };
  },
  mounted() {
    this.$store.dispatch(GET_MEMBERS, {
      id: this.$route.params.id,
      params: { dropdown: true }
    });
    this.changeArchivedItems(dashboardItems.tasks);
  },
  methods: {
    async changeArchivedItems(item) {
      this.loading = true;

      if (item === dashboardItems.tasks) {
        await this.loadTasks();
        this.component = ArchivedTasks;
      } else if (item === dashboardItems.rewards) {
        await this.loadRewards();
        this.component = ArchivedRewards;
      } else {
        await this.loadMissions();
        this.component = ArchivedMissions;
      }

      this.loading = false;
    },
    async loadTasks() {
      const { id } = this.$route.params;

      await this.$store.dispatch(GET_GROUP_TASKS, {
        groupId: id,
        params: {
          archived: true
        }
      });
    },
    async loadRewards() {
      const { id } = this.$route.params;

      await this.$store.dispatch(GET_GROUP_REWARDS, {
        groupId: id,
        params: {
          archived: true
        }
      });
    },
    async loadMissions() {
      const { id } = this.$route.params;

      await this.$store.dispatch(GET_GROUP_MISSIONS, {
        groupId: id,
        params: {
          status: missionStatuses.archived
        }
      });
    }
  }
};
</script>
