<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" small class="mr-n1"> mdi-dots-horizontal </v-icon>
    </template>
    <v-list>
      <div v-for="action of actions" :key="action.eventName">
        <v-list-item v-if="action.cond" @click="$emit(action.eventName)">
          <v-list-item-title class="app-dark-gray--text">
            <v-icon left class="mr-1">{{ action.icon }}</v-icon>
            {{ $t(`common.${action.eventName}`) }}
          </v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'actions-menu',
  props: {
    canEdit: {
      type: Boolean,
      default: false
    },
    canArchive: {
      type: Boolean,
      default: false
    },
    canClone: {
      type: Boolean,
      default: false
    },
    canReopen: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    actions() {
      const actions = [];

      if (this.canEdit) {
        actions.push({
          eventName: 'edit',
          cond: this.canEdit,
          icon: 'mdi-pencil'
        });
      }

      if (this.canArchive) {
        actions.push({
          eventName: 'archive',
          cond: this.canArchive,
          icon: 'mdi-archive'
        });
      }

      if (this.canClone) {
        actions.push({
          eventName: 'clone',
          cond: this.canClone,
          icon: 'mdi-content-copy'
        });
      }

      if (this.canReopen) {
        actions.push({
          eventName: 'reopen',
          cond: this.canReopen,
          icon: 'mdi-content-copy'
        });
      }

      actions.push({ eventName: 'delete', cond: true, icon: 'mdi-delete' });

      return actions;
    }
  }
};
</script>
