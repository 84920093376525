<template>
  <span v-if="mission.status === missionStatuses.active">
    <v-chip
      v-if="missionDaysLabelColor === missionLabelColors.red"
      color="app-red"
      small
      dark
      class="mt-0 ml-0"
    >
      <v-icon left small class="mr-2">mdi-bell-alert</v-icon>
      <span class="text-subtitle-2"
        >{{ daysLeft }} {{ $t('missions.daysLeft') }}</span
      >
    </v-chip>
    <v-chip
      v-else-if="missionDaysLabelColor === missionLabelColors.orange"
      color="app-orange"
      small
      dark
      class="mt-0 ml-0"
    >
      <v-icon left small class="mr-2">mdi-bell-alert</v-icon>
      <span class="text-subtitle-2"
        >{{ daysLeft }} {{ $t('missions.daysLeft') }}</span
      >
    </v-chip>
    <v-chip v-else color="app-green" small dark class="ml-0">
      <v-icon left small class="mr-2">mdi-bell</v-icon>
      <span class="text-subtitle-2"
        >{{ daysLeft }} {{ $t('missions.daysLeft') }}</span
      >
    </v-chip>
  </span>
  <span v-else>
    <v-chip
      v-if="mission.status === missionStatuses.pending"
      small
      dark
      color="app-blue"
      class="mt-0 ml-0"
    >
      {{ $t('missions.pending') }}
    </v-chip>
    <v-chip
      v-else-if="mission.status === missionStatuses.completed"
      small
      dark
      color="app-green"
      class="mt-0 ml-0"
    >
      <v-icon left small class="mr-2">mdi-emoticon-happy-outline</v-icon>
      {{ $t('missions.completed') }}
    </v-chip>
    <v-chip
      v-else-if="mission.status === missionStatuses.waitingApproval"
      small
      dark
      color="app-orange"
      class="mt-0 ml-0"
    >
      {{ $t('missions.waitingApproval') }}
    </v-chip>
    <v-chip
      v-else-if="mission.status === missionStatuses.archived"
      small
      dark
      color="app-orange"
      class="mt-0 ml-0"
    >
      <v-icon left small class="mr-2">mdi-archive</v-icon>
      {{ $t('missions.archived') }}
    </v-chip>
    <v-chip v-else color="app-red" small dark class="mt-0 ml-0">
      <v-icon left small class="mr-2">mdi-emoticon-sad-outline</v-icon>
      {{ $t('missions.failed') }}
    </v-chip>
  </span>
</template>

<script>
import dateTimeManager from '../../common/date.time.manager';
import missionStatuses from '../../enums/mission.statuses';

const MISSION_LABEL_COLORS = {
  green: 'green',
  orange: 'orange',
  red: 'red'
};

export default {
  name: 'mission-status-label',
  props: {
    mission: Object
  },
  computed: {
    daysLeft() {
      let days = dateTimeManager.diffInDays(new Date(), this.mission.to);

      if (days < 0) {
        days = 0;
      }

      return days;
    },
    missionDaysLabelColor() {
      const missionsDays = dateTimeManager.diffInDays(
        this.mission.from,
        this.mission.to
      );
      const daysLeft = dateTimeManager.diffInDays(new Date(), this.mission.to);
      let color = MISSION_LABEL_COLORS.green;

      if (missionsDays / 4 >= daysLeft) {
        color = MISSION_LABEL_COLORS.red;
      } else if (missionsDays / 2 >= daysLeft) {
        color = MISSION_LABEL_COLORS.orange;
      }

      return color;
    }
  },
  data() {
    return {
      missionStatuses,
      missionLabelColors: MISSION_LABEL_COLORS
    };
  }
};
</script>
