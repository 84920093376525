<template>
  <v-row class="mt-0" v-if="rewards.length > 0">
    <v-col v-for="reward of rewards" :key="reward.id" md="4" sm="6" cols="12">
      <archived-reward :reward="reward"></archived-reward>
    </v-col>
  </v-row>
  <div v-else class="text-center py-4">
    <h1 class="heading app-dark-gray--text font-weight-light">
      {{ $t('rewards.noRewardsFound') }}
    </h1>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ArchivedReward from './ArchivedReward.vue';

export default {
  name: 'archived-rewards',
  components: {
    ArchivedReward
  },
  computed: {
    ...mapGetters(['rewards'])
  }
};
</script>
