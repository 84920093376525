<template>
  <v-select
    v-model="selectedItem"
    :items="items"
    item-value="value"
    item-text="text"
    solo
    @change="selectItem"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
export default {
  data() {
    return {
      selectedItem: 'tasks',
      items: [
        { text: this.$i18n.t('tasks.tasks'), value: 'tasks' },
        {
          text: this.$i18n.t('missions.missions'),
          value: 'missions'
        },
        { text: this.$i18n.t('rewards.rewards'), value: 'rewards' }
      ]
    };
  },
  methods: {
    selectItem() {
      this.$emit('changeArchivedItems', this.selectedItem);
    }
  }
};
</script>
