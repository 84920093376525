function getTasksListsConfiguration(todos, dailies, weeklies) {
  return [
    {
      name: 'todo',
      tasks: todos,
      borderClass: 'border-top-blue',
      textClass: 'app-blue--text',
      i18nKey: 'tasks.todo'
    },
    {
      name: 'daily',
      tasks: dailies,
      borderClass: 'border-top-green',
      textClass: 'app-green--text',
      i18nKey: 'tasks.daily'
    },
    {
      name: 'weekly',
      tasks: weeklies,
      borderClass: 'border-top-orange',
      textClass: 'app-orange--text',
      i18nKey: 'tasks.weekly'
    }
  ];
}

export default {
  getTasksListsConfiguration
};
