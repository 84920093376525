import colorsEnum from '../enums/colors.js';

export default {
  computed: {
    textRubyColor() {
      const { ruby } = this.mission;

      if (ruby >= 0 && ruby <= 4999) {
        return `${colorsEnum.green}--text`;
      } else if (ruby > 4999 && ruby <= 7999) {
        return `${colorsEnum.orange}--text`;
      } else {
        return `${colorsEnum.red}--text`;
      }
    }
  }
};
