import colorsEnum from '../enums/colors';

export default {
  computed: {
    rubyColor() {
      const { ruby } = this.task;
      if (ruby >= 100 && ruby <= 499) {
        return colorsEnum.green;
      } else if (ruby > 499 && ruby <= 799) {
        return colorsEnum.orange;
      } else {
        return colorsEnum.red;
      }
    },
    rubyColorText() {
      const { ruby } = this.task;
      if (ruby >= 100 && ruby <= 499) {
        return `${colorsEnum.green}--text`;
      } else if (ruby > 499 && ruby <= 799) {
        return `${colorsEnum.orange}--text`;
      } else {
        return `${colorsEnum.red}--text`;
      }
    }
  }
};
