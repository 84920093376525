<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular">
        {{ $t('missions.info') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container class="px-4 pt-1">
      <v-card-title class="pb-1 pl-0" v-if="mission.group">
        <v-chip class="ml-0" small label color="app-green" dark>
          <span class="text-body-2 font-weight-medium">
            {{ mission.group.name }}
          </span>
        </v-chip>
      </v-card-title>
      <v-row class="pt-1 pb-3">
        <v-col cols="12">
          <v-card-title class="text-subtitle-1 pl-0 py-0">
            <span class="font-weight-medium">
              {{ mission.name }}
            </span>
          </v-card-title>
          <v-card-text class="text-body-2 pl-0 py-0">
            <span class="font-weight-light one-line" v-if="mission.notes">
              {{ mission.notes }}
            </span>

            <span class="text-body-2 font-weight-light">
              {{ mission.from | dateFormat }} -
              {{ mission.to | dateFormat }}
            </span>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" class="py-0">
          <span class="font-weight-bold">
            {{ $t('common.requireApprove') }}:
          </span>
          <span v-if="mission.requireApprove">{{ $t('common.yes') }}</span>
          <span v-else>{{ $t('common.no') }}</span>
        </v-col>
      </v-row>
      <v-row v-if="approveMarked" class="mb-2">
        <v-col cols="12" class="py-0">
          <span class="font-weight-bold pr-2">
            {{ $t('common.approved') }}:
          </span>
          <v-icon v-if="mission.approved" small color="app-green">
            mdi-thumb-up
          </v-icon>
          <v-icon v-else small color="app-red">mdi-thumb-down</v-icon>
        </v-col>
      </v-row>
      <v-card-actions class="pb-1 px-0">
        <mission-progress-bar :mission="mission"></mission-progress-bar>
      </v-card-actions>
      <v-card-actions class="py-2 pl-0">
        <mission-status-label :mission="mission"></mission-status-label>
        <v-spacer></v-spacer>
        <v-row align="center" justify="end" class="pr-1">
          <ruby-label
            :ruby="mission.ruby"
            :text-color="textRubyColor"
          ></ruby-label>
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import MissionProgressBar from '../../missions/MissionProgressBar';
import MissionStatusLabel from '../../missions/MissionStatusLabel';
import RubyLabel from '../../shared/RubyLabel';
import missionRubyColorMixin from '../../../mixins/mission.ruby.color.mixin';

export default {
  name: 'group-mission-info-modal',
  props: {
    mission: Object
  },
  components: {
    MissionProgressBar,
    MissionStatusLabel,
    RubyLabel
  },
  mixins: [missionRubyColorMixin],
  computed: {
    approveMarked() {
      return (
        this.mission.requireApprove &&
        this.mission.approved !== null &&
        this.mission.approved !== undefined
      );
    }
  }
};
</script>
