<template>
  <v-card
    v-if="mission.id"
    class="px-2 pb-0 cursor"
    :class="topBorderClass"
    @click="openInfo"
    tile
    :ripple="false"
  >
    <v-container class="px-3 py-0">
      <v-card-title
        class="no-flex-wrap pt-2 pl-0"
        v-bind:class="{ 'pb-0': isLeader, 'pb-2': !isLeader }"
      >
        <v-spacer></v-spacer>
        <actions-menu
          class="mr-n-3"
          :canReopen="mission.status === statuses.archived"
          @reopen="reopenMission"
          @delete="deleteMission"
        ></actions-menu>
      </v-card-title>
      <v-row align="center">
        <v-col
          class="pt-0"
          v-bind:class="{
            'col-12': !mission.requireApprove,
            'col-11': mission.requireApprove
          }"
        >
          <v-card-title class="text-h6 pl-0 pt-0 pb-0">
            <span class="two-lines font-weight-regular">
              {{ mission.name }}
            </span>
          </v-card-title>
          <div class="text-subtitle-1 app-dark-gray--text font-weight-regular">
            {{ mission.notes }}
          </div>
          <span class="text-body-2 font-weight-light">
            {{ mission.from | dateFormat }} -
            {{ mission.to | dateFormat }}
          </span>
        </v-col>
        <v-col v-if="mission.requireApprove" cols="1" class="pt-0 px-0">
          <v-icon small color="app-orange"> mdi-circle </v-icon>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="pt-0 pb-1 px-2">
      <mission-progress-bar :mission="mission"></mission-progress-bar>
    </v-card-actions>
    <v-card-actions class="pt-1">
      <mission-status-label :mission="mission"></mission-status-label>
      <v-spacer></v-spacer>
      <v-row align="center" justify="end" class="pr-3">
        <ruby-label
          :ruby="mission.ruby"
          :text-color="textRubyColor"
        ></ruby-label>
      </v-row>
    </v-card-actions>
    <v-divider class="mt-1" light></v-divider>
    <v-card-actions>
      <div @click.stop="openAssignedUsers()">
        <assigned-users :assignedUsers="assignedUsers"></assigned-users>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DELETE_GROUP_MISSION } from '../../../../store/missions/types';
import ActionsMenu from '../../../shared/ActionsMenu.vue';
import MissionProgressBar from '../../../missions/MissionProgressBar';
import MissionStatusLabel from '../../../missions/MissionStatusLabel.vue';
import RubyLabel from '../../../shared/RubyLabel';
import ManageGroupMissionModal from '../../missions/ManageGroupMissionModal';
import GroupMissionInfoModal from '../../missions/GroupMissionInfoModal';
import AssignedUsers from '../../AssignedUsers';
import AssignedUsersModal from '../../AssignedUsersModal';
import ConfirmModal from '../../../shared/ConfirmModal';
import missionRubyColorMixin from '../../../../mixins/mission.ruby.color.mixin';
import manageModalsType from '../../../../enums/manage.modals.types';
import dateTimeManager from '../../../../common/date.time.manager';
import missionStatuses from '../../../../enums/mission.statuses';

const GROUP_MISSION_INFO_MODAL_CONFIG = {
  width: '400px'
};

export default {
  name: 'archived-mission',
  components: {
    ActionsMenu,
    MissionProgressBar,
    MissionStatusLabel,
    RubyLabel,
    AssignedUsers
  },
  props: {
    mission: {
      type: Object,
      required: true
    },
    isDetailPage: {
      type: Boolean,
      default: false
    },
    topBorderClass: {
      type: String,
      default: 'border-top-blue'
    },
    isLeader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    approveMarked() {
      return (
        this.mission.approved !== null && this.mission.approved !== undefined
      );
    },
    assignedUsers() {
      return this.mission.assignedUsers?.length > 0
        ? this.mission.assignedUsers
        : [];
    },
    statuses() {
      return missionStatuses;
    }
  },
  mixins: [missionRubyColorMixin],
  methods: {
    reopenMission() {
      const missionData = {
        ...this.mission,
        from: dateTimeManager.toICODateString(new Date()),
        to: ''
      };

      this.$root.$modal.show(ManageGroupMissionModal, {
        selectedMission: missionData,
        title: this.$i18n.t('missions.reopenMission'),
        modalType: manageModalsType.reopen
      });
    },
    openInfo() {
      this.$root.$modal.show(
        GroupMissionInfoModal,
        {
          mission: this.mission
        },
        GROUP_MISSION_INFO_MODAL_CONFIG
      );
    },
    openAssignedUsers() {
      this.$root.$modal.show(
        AssignedUsersModal,
        {
          itemName: this.mission.name,
          assignedUsers: this.assignedUsers
        },
        {
          width: '360px'
        }
      );
    },
    async deleteMission() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('missions.deleteMissionConfirm')
      });

      if (!confirmed) return;

      const { id } = this.$route.params;

      this.$store.dispatch(DELETE_GROUP_MISSION, {
        groupId: id,
        missionId: this.mission.id
      });
    },
    navigateToDetailPage() {
      this.$router.push({ path: `/missions/${this.mission.id}` });
    }
  }
};
</script>
